// import React from "react";
// import { Navigate } from "react-router-dom";

// export default function PrivateRoute({ component }) {
//   const userToken = localStorage.getItem("token");
//   if (userToken && userToken.length) {
//     return component;
//   }
//   return <Navigate to={"/login"} />;
// }

import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const PrivateRoute = ({ component }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/admin/verify-admin-token`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const isAdmin = response?.data?.user?.isAdmin;
        if (isAdmin) {
          setIsAuthenticated(isAdmin);
        } else {
          localStorage.removeItem("token");
          toast.error("Admin token expired");
        }
      } catch (error) {
        console.error("Error verifying token: ", error);
        setIsAuthenticated(false);
        localStorage.removeItem("token");
        toast.error("Admin token expired");
      } finally {
        setLoading(false);
      }
    };

    verifyToken();
  }, [component]);

  if (loading) {
    return <div>Loading...</div>; // Display a loading state while checking token
  }

  if (isAuthenticated) {
    return component;
  }

  return <Navigate to={"/login"} />;
};

export default PrivateRoute;
