import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ValidateInputs } from "../../../helper/helper";

const MerchantWithdrawFeeCard = () => {
  // Changed the name to uppercase
  const [formData, setFormData] = useState({
    platformFee: "",
    adminWallet: "",
  });
  const [errors, setErrors] = useState({});
  const [adminFeeInfo, setAdminFeeInfo] = useState(null); // Current fee from the server

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Ensure ValidateInputs is defined or imported
    const updatedErrors = await ValidateInputs(name, value, errors);
    setErrors(updatedErrors);
  };

  // Function to compare formData and adminFeeInfo
  const isFormChanged = () => {
    return (
      formData.platformFee !== adminFeeInfo?.platformFee ||
      formData.adminWallet !== adminFeeInfo?.adminWallet
    );
  };

  const fetchPlatformFee = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/get-platform-fee`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const data = response?.data?.data;
        setAdminFeeInfo(data);
        setFormData(data); // Set form fields with fetched data
      }
    } catch (error) {
      console.error("Error fetching Platform Fee:", error);
    }
  };

  const handleSetFee = async (event) => {
    event.preventDefault(); // Prevent form submission from reloading the page

    // Check for errors before proceeding
    if (errors.platformFee || errors.adminWallet) {
      return;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/admin/update-platform-fee`,
        {
          platformFee: formData.platformFee,
          adminWallet: formData.adminWallet,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Platform Fee updated successfully!");
        setAdminFeeInfo(formData); // Update the adminFeeInfo with the new data
      } else {
        toast.error("Failed to update the platform fee. Please try again.");
      }
    } catch (error) {
      console.error("Error updating Platform Fee:", error);
      toast.error("An error occurred while updating the Platform Fee.");
    }
  };

  useEffect(() => {
    fetchPlatformFee();
  }, []);

  return (
    <div className="col-md-4">
      <div className="card card-primary">
        <form id="quickForm" onSubmit={handleSetFee}>
          <div
            className="card-header"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <h3 className="card-title">Merchant Withdraw Platform Fee</h3>
          </div>

          <div className="card-body">
            <div className="form-group">
              <label htmlFor="platformFee">Platform Fee (%)</label>
              <input
                type="number"
                name="platformFee"
                className={`form-control ${
                  errors.platformFee ? "is-invalid" : ""
                }`}
                value={formData.platformFee}
                onChange={handleChange}
                placeholder="Enter platform fee in percent: 5%"
              />
              {errors.platformFee && (
                <div className="invalid-feedback">{errors.platformFee}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="adminWallet">Admin Wallet Address</label>
              <input
                type="text"
                name="adminWallet"
                className={`form-control ${
                  errors.adminWallet ? "is-invalid" : ""
                }`}
                value={formData.adminWallet}
                onChange={handleChange}
                placeholder="Enter admin wallet address"
              />
              {errors.adminWallet && (
                <div className="invalid-feedback">{errors.adminWallet}</div>
              )}
            </div>
          </div>
          <div
            className="card-footer"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              type="submit"
              className="btn btn-primary"
              disabled={adminFeeInfo === formData}
            >
              Set Fee & Address
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MerchantWithdrawFeeCard;
