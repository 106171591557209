import React, { useEffect, useState } from "react";
import { IoCopyOutline } from "react-icons/io5";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { formatDate, trimAddress, copyToClipboard } from "../../helper/helper";
import CustomPagination from "../../components/CustomPagination";

const ViewAppTransaction = (props) => {
  const [history, setHistory] = useState([]);
  const { userId } = useParams();

  const [loading, setLoading] = useState(true); // Add loading state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  console.log("appId", userId);

  const getHistory = async () => {
    if (!props?.address) return;
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchant-app-tx/get-appid-transaction`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            pageNo: currentPage,
            limitVal: 25,
            search: searchQuery,
            appId: userId,
          },
        }
      );
      if (res?.data) {
        setHistory(res?.data?.data);
        setTotalPages(res?.data?.totalPages);
      }
      setLoading(false);

      console.log("response", res?.data);
    } catch (err) {
      console.log("Error getting wallet history: ", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getHistory();
  }, [props?.address, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  console.log("history is", history);

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-2 d-flex justify-content-between">
          <div className="col-sm-6">
            <h1 className="m-0">App Transactions</h1>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                {loading ? (
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                ) : (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>SN</th>
                        <th>Type</th>
                        <th>Chain Id</th>
                        <th>Transactions Hash</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Time Stamp</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {history.length > 0 ? (
                        history.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data?.type.toLowerCase()}</td>
                            <td>{data?.chainId}</td>
                            <td>
                              {trimAddress(data?.hash, 10, 5)}
                              {data?.hash ? (
                                <button
                                  onClick={() => copyToClipboard(data?.hash)}
                                  style={{
                                    marginLeft: "1rem",
                                    border: "none",
                                    background: "transparent",
                                  }}
                                >
                                  <IoCopyOutline />
                                </button>
                              ) : (
                                "--------"
                              )}
                            </td>
                            <td>
                              {trimAddress(data?.fromAddress, 10, 5)}

                              {data?.fromAddress ? (
                                <button
                                  onClick={() =>
                                    copyToClipboard(data?.fromAddress)
                                  }
                                  style={{
                                    marginLeft: "1rem",
                                    border: "none",
                                    background: "transparent",
                                  }}
                                >
                                  <IoCopyOutline />
                                </button>
                              ) : (
                                "---------------"
                              )}
                            </td>
                            <td>
                              {trimAddress(data?.toAddress ?? data?.to, 10, 5)}
                              <button
                                onClick={() =>
                                  copyToClipboard(data?.toAddress ?? data?.to)
                                }
                                style={{
                                  marginLeft: "1rem",
                                  border: "none",
                                  background: "transparent",
                                }}
                              >
                                <IoCopyOutline />
                              </button>
                            </td>

                            <td>
                              {data?.recivedAmount ?? data?.amount}{" "}
                              {data?.symbol}
                            </td>
                            <td>{data?.status}</td>
                            <td>{formatDate(data?.createdAt)}</td>

                            <td>
                              <Link
                                to={`/view-payment-link/${data?._id}`}
                                className="btn btn-link merch-view"
                              >
                                <i className="fas fa-eye"></i>
                              </Link>
                            </td>

                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="9">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                marginTop: "20px",
                              }}
                            >
                              <p>Data not found</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAppTransaction;
