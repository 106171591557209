import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const [verificationToken, setVerificationToken] = useState("");

  const [newPassword, setNewPassword] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const navigate = useNavigate();

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    if (token) {
      setVerificationToken(token);
    }
  }, [location.search]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newPassword) {
      setError("Please enter Password.");
      return;
    }
    setError("");

    if (!confirmPassword) {
      setError("Please enter Confirm Password.");
      return;
    }
    setError("");

    if (newPassword !== confirmPassword) {
      setError("NewPassword and ConfirmPassword must be same");
      return;
    }
    setError("");

    if (newPassword.length < 6) {
      setError("Please enter Minium 6 digits NewPassword.");
      return;
    }
    setError("");

    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+|}{":?><,./;'[\]\\`~]).{6,}$/;
    if (!passwordRegex.test(newPassword)) {
      setError(
        "Password must contain at least one uppercase letter, one lowercase letter, one special character and one number."
      );
      return;
    }

    setError("");

    try {
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/admin/reset-password`, {
          verificationToken: verificationToken,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        })
        .then((response) => {
          toast.success("Password changed successfully!");
          navigate("/login");
        })
        .catch((error) => {
          console.error("Error fetching user types:", error);
        });
    } catch (error) {
      console.log("catch error", error);
      setError("error");
    }
  };

  //show password
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  return (
    <>
      <div className="login-box">
        <div className="login-logo">
          <b style={{ fontSize: "Larger", color: "white" }}>Reset Password</b>
        </div>
        <div className="card forget-card">
          <div className="card-body register-card-body forget-register">
            {/* <p className="login-box-msg">Forget Password</p> */}
            {error && <p className="text-danger">{error}</p>}
            <form onSubmit={handleSubmit}>
              <div className="input-group mb-3">
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="form-control"
                  placeholder="Password"
                  value={newPassword}
                  onChange={handleNewPassword}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span onClick={togglePasswordVisibility}>
                      <i
                        className={
                          passwordVisible ? "fas fa-eye-slash" : "fas fa-eye"
                        }
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type={confirmPasswordVisible ? "text" : "password"}
                  className="form-control"
                  placeholder="confirm Password"
                  value={confirmPassword}
                  onChange={handleConfirmPassword}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                  <span onClick={toggleConfirmPasswordVisibility}>
                      <i
                        className={
                          confirmPasswordVisible ? "fas fa-eye-slash" : "fas fa-eye"
                        }
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginTop: "50px" }}>
                <div className="col-12">
                  <button type="submit" className="btn btn-primary btn-block">
                    Reset Password
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
