import React from 'react'
import Header from '../../components/Header'
import SideNav from '../../components/SideNav'
import { Outlet } from 'react-router-dom'
import Footer from '../../components/Footer'

export default function AdminLayout() {
  return (
    <div className="wrapper">
        <Header />
        <SideNav />
        <Outlet/>
        <Footer />
    </div>
  )
}
