import React, { useEffect, useState } from "react";
import { IoCopyOutline } from "react-icons/io5";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import ViewAppTransaction from "../MerchantAppsModules/ViewAppTransaction";
import {
  copyToClipboard,
  formatDate,
  getAddressLink,
} from "../../helper/helper";
import {
  deposit_bnb_for_gas_fee,
  getBalance,
  getERC20TransferTxFee,
  getNetwork,
} from "../../helper/evm.helper";

const ViewPaymentLink = () => {
  const { paymentId } = useParams();
  const [paymentInfo, setPaymentInfo] = useState("");
  const [txLink, setTxLink] = useState(""); // State to store the dynamic link
  const [isLoading, setIsLoading] = useState(false);
  const [cryptoInfo, setCryptoInfo] = useState({
    isNative: false,
    symbol: "",
    nativeSymbol: "",
    nativeBalance: 0,
    balance: 0,
    gas: 0,
  });

  const fetchAppsDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/payment-link/get-tx-status`,
        {
          params: {
            paymentId,
          },
        }
      );

      if (response?.status == 200) {
        setPaymentInfo(response?.data?.data);

        // set the transaction link
        const link = await getAddressLink(
          response?.data?.data?.chainId,
          response?.data?.data?.toAddress
        );
        setTxLink(link); // Update the state with the fetched link

        const code = response?.data?.data?.code.split(".");

        setCryptoInfo({
          isNative: code.length == 2,
          symbol: code[0],
          nativeSymbol: code.length > 1 ? code[1] : code[0],
          nativeBalance: 0,
          balance: 0,
          gas: 0,
        });
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    fetchAppsDetails();
  }, [paymentId]);

  console.log("paymentInfo : ", paymentInfo);

  console.log("cryptoInfo : ", cryptoInfo);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2 d-flex justify-content-between">
              <div className="col-sm-6 mb-2">
                <h1 className="m-0">View Payment Links </h1>
              </div>
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-body view-page">
                    <>
                      <div className="user-info">
                        <strong>Payment ID:</strong>
                        <span style={{ marginLeft: "2rem" }}>
                          {paymentInfo?._id}
                        </span>
                      </div>

                      <div className="user-info">
                        <strong>Chain Id:</strong>
                        <span style={{ marginLeft: "2rem" }}>
                          {paymentInfo?.chainId}
                        </span>
                      </div>

                      <div className="user-info">
                        <strong>Crypto Code:</strong>
                        <span style={{ marginLeft: "2rem" }}>
                          {paymentInfo?.code}
                        </span>
                      </div>

                      <div className="user-info">
                        <strong>Amount to Received:</strong>
                        <span style={{ marginLeft: "1rem" }}>
                          {paymentInfo?.amount} {paymentInfo?.symbol}
                        </span>
                      </div>

                      <div className="user-info">
                        <strong>Amount Received:</strong>
                        <span style={{ marginLeft: "1rem" }}>
                          {paymentInfo?.amount} {paymentInfo?.symbol}
                        </span>
                      </div>

                      <div className="user-info">
                        <strong>Fund reciver address:</strong>
                        <span
                          style={{
                            marginLeft: "2rem",
                            color: "blue",
                            textDecoration: "underline",
                          }}
                        >
                          <a
                            href={txLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {paymentInfo?.toAddress}
                          </a>
                        </span>

                        <button
                          onClick={() =>
                            copyToClipboard(paymentInfo?.toAddress)
                          }
                          style={{
                            marginLeft: "2rem",
                            border: "none",
                            background: "transparent",
                          }}
                        >
                          <IoCopyOutline />
                        </button>
                      </div>

                      <div className="user-info">
                        <strong>Token Address :</strong>
                        <span style={{ marginLeft: "2rem" }}>
                          {paymentInfo?.tokenAddress}
                        </span>
                      </div>

                      <div className="user-info">
                        <strong>Buyer Email:</strong>
                        <span style={{ marginLeft: "2rem" }}>
                          {paymentInfo?.buyerEmail}
                        </span>
                      </div>

                      <div className="user-info">
                        <strong>Expiry Time:</strong>
                        <span style={{ marginLeft: "2rem" }}>
                          {formatDate(paymentInfo?.expireTime)}
                        </span>
                      </div>

                      <div className="user-info">
                        <strong>Created At:</strong>
                        <span style={{ marginLeft: "2rem" }}>
                          {formatDate(paymentInfo?.createdAt)}
                        </span>
                      </div>

                      <div className="user-info">
                        <strong>Payment Status:</strong>
                        <span style={{ marginLeft: "2rem" }}>
                          {paymentInfo?.status}
                        </span>
                      </div>

                      <div className="user-info">
                        <strong>Withdrawal Status:</strong>
                        <span style={{ marginLeft: "2rem" }}>
                          {paymentInfo?.withdrawStatus}
                        </span>
                      </div>
                    </>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 mb-2">
                <h2 className="m-0">Current Balance </h2>
              </div>
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-body view-page">
                    <>
                      <div className="user-info">
                        <strong>Transaction Fee :</strong>
                        <span style={{ marginLeft: "2rem" }}>
                          {cryptoInfo?.gas} {cryptoInfo?.nativeSymbol}
                        </span>
                      </div>

                      {cryptoInfo?.isNative && (
                        <div className="user-info">
                          <strong>{cryptoInfo?.nativeSymbol} balance :</strong>
                          <span style={{ marginLeft: "2rem" }}>
                            {cryptoInfo?.nativeBalance}{" "}
                            {cryptoInfo?.nativeSymbol}
                          </span>
                        </div>
                      )}

                      <div className="user-info">
                        <strong>{cryptoInfo?.symbol} balance :</strong>
                        <span style={{ marginLeft: "2rem" }}>
                          {cryptoInfo?.balance} {cryptoInfo?.symbol}
                        </span>
                      </div>

                      <div
                        className="user-info"
                        style={{
                          display: "flex",
                          displayAlign: "center",
                          gap: "10px",
                        }}
                      >
                        <button
                          onClick={async () => {
                            const output = await getERC20TransferTxFee(
                              await getNetwork(paymentInfo?.chainId.toString()).rpc,
                              paymentInfo?.tokenAddress,
                              paymentInfo?.amount,
                              18,
                              paymentInfo?.toAddress,
                              "0x5B18D497d088540c4434Db7824cC9b1b06f02805"
                            );

                            console.log("output : ", output);

                            setCryptoInfo((prev) => ({
                              ...prev,
                              gas: output?.gasFeeInEther ?? prev.gas,
                            }));
                          }}
                        >
                          Fetch required transaction Fee
                        </button>
                        <button
                          onClick={async () => {
                            console.log("Withdraw Fee");
                            const balance = await getBalance(
                                paymentInfo?.chainId.toString(),
                              paymentInfo?.toAddress,
                              paymentInfo?.tokenAddress
                            );

                            setCryptoInfo((prev) => ({
                              ...prev,
                              nativeBalance:
                                balance?.ethBalanceEther ??
                                prev.ethBalanceEther,
                              balance:
                                balance?.tokenBalanceEther ??
                                balance?.tokenBalanceEther,
                            }));

                            console.log("balance : ", balance);
                          }}
                        >
                          Fetch updated Balance
                        </button>
                      </div>
                      <div
                        className="user-info"
                        style={{
                          display: "flex",
                          displayAlign: "center",
                          gap: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <label>Withdraw Admin Charges:</label>
                        <input
                          type="text"
                          // value={address}
                          // onChange={handleChange}
                          placeholder="Enter address"
                          required // Make the input field required
                        />
                        <button>Submit</button>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPaymentLink;
