import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Reports from "../ReportManagement/Reports";

const Dashboard = () => {
  const [merchantCount, setMerchantCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [inquiryCount, setInquiryCount] = useState(0);

  useEffect(() => {
    const totalMerchant = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/merchants/count`
        );

        setMerchantCount(response?.data?.count);
      } catch (error) {
        console.error("Error fetching merchant count:", error);
      }
    };
    totalMerchant();
  }, []);

  useEffect(() => {
    const totalUser = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_EXTENSION_API_URL}/users/count`
        );

        setUserCount(response?.data?.count);
      } catch (error) {
        console.error("Error fetching user count:", error);
      }
    };
    totalUser();
  }, []);

  useEffect(() => {
    const totalInquiry = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/inquiry/count`
        );

        setInquiryCount(response?.data?.count);
      } catch (error) {
        console.error("Error fetching merchant count:", error);
      }
    };
    totalInquiry();
  }, []);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Dashboard</h1>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-6">
                <div className="small-box bg-danger">
                  <div className="inner">
                    <h3>{inquiryCount}</h3>
                    <p>Inquiry</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-pie-graph" />
                  </div>
                  <Link to="/inquiry" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>

              <div className="col-lg-3 col-6">
                <div className="small-box bg-info">
                  <div className="inner">
                    <h3>{userCount}</h3>
                    <p>New Users</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add" />
                  </div>
                  <Link to="/users" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>

              <div className="col-lg-3 col-6">
                <div className="small-box bg-warning">
                  <div className="inner">
                    <h3>{merchantCount}</h3>
                    <p>Merchant Registrations</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add" />
                  </div>
                  <Link to="/merchants" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div style={{marginTop: '1rem'}}></div>

        <Reports merchantCount={merchantCount} walletUsers={userCount} />
      </div>
    </div>
  );
};

export default Dashboard;
