import React from "react";
import MerchantWithdrawFeeCard from "./Components/MerchantWithdrawFeeCard";
import PaymentLinkFeeCard from "./Components/PaymentLinkFeeCard";

const FeeManagement = () => {
  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div
                className="col-sm-12"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <h1 className="m-0">Platform Fee Update/Management</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="container-fluid">
              <div className="row">
                {/* First column for the form */}
                <MerchantWithdrawFeeCard />

                {/* Second column for PaymentLinkFeeCard */}
                <PaymentLinkFeeCard />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeeManagement;
